export default {
  getCount: state => state.count,
  getIsFirstLogin: state => state.isFirstLogin,
  getCountApplicationsIncomplete: state => state.countApplicationsInomplete,
  getNotifications: state => state.notifications,
  getPersonalityTestNotification: state => state.personalityNotification,
  getSuggestedNotifications: state => state.suggestedNotifications,
  getAmountRedeemable: state => state.amountRedeemable,
  getTransactions: state => state.transactions
}


import Vue from 'vue'
export default Vue.extend({
  name: 'Header',
  data () {
    return {
      isMenuOpen: false,
      popupError: false,
      error: {},
      $gtm: null
    }
  },
  computed: {
    getUserName() {
      const capitalize = (str) => {
        if (!str) { return '' }
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
      }
      const firstName = capitalize(this.$auth.user.first_name)
      const lastName = capitalize(this.$auth.user.last_name)

      return `${firstName} ${lastName}`
    },
    profileLink () {
      let width = 768
      if (process.browser) {
        width = window.innerWidth
      }
      if (width < 768) {
        return 'user-settings'
      } else {
        return 'user-settings-profile'
      }
    }
  },
  beforeCreate() {
    if (this.$auth.loggedIn) {
      this.$i18n.setLocale(this.$auth.user.locale)
    }
  },
  methods: {
    goToPersonalArea() {
      this.track()
      this.trackMenu('Username', 'text')
      const width = window?.innerWidth
      // Verifica se `navigator` è disponibile e usa il fallback `""` se non lo è
      const userAgent = typeof navigator !== 'undefined' ? navigator?.userAgent?.toLowerCase() : ''
      const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent)
      // Imposta il percorso in base al tipo di dispositivo
      const path = isMobile || (width < 768) ? 'user-settings' : 'user-settings-profile'
      this.$router.push(this.localePath({ name: path, params: { locale: this.$i18n.locale } }))
    },
    redirectToPlatform() {
      window.open('https://business.cving.com/per-le-aziende', '_blank')
    },
    trackMenu (menu:string, type:string) {
      this.$gtm.push({
        event: 'click_menu',
        event_data: {
          menu_location: '',
          menu_type: type,
          menu_text: menu,
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    },
    track () {
      this.$gtm.push({
        event: 'click_profile',
        event_data: {
          page_url: this.$router.currentRoute.fullPath,
          page_title: document ? document?.title : 'CVing'
        }
      })
    }
  }
})

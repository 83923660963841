export default () => ({
  count: 0,
  isFirstLogin: false,
  countApplicationsInomplete: 0,
  notifications: [],
  personalityNotification: false,
  suggestedNotifications: [],
  amountRedeemable: 0,
  transactions: []
})

export default {
  setCount: ({ commit }, payload) => {
    commit('SET_COUNT', payload)
  },

  setIsFirstLogin: ({ commit }, payload) => {
    commit('SET_IS_FIRST_LOGIN', payload)
  },

  setCountApplicationsIncomplete: ({ commit }, payload) => {
    commit('SET_APPLICATIONS_INCOMPLETE', payload)
  },

  setNotifications: ({ commit }, payload) => {
    commit('SET_NOTIFICATIONS', payload)
  },

  setPersonalityTestNotification: ({ commit }, payload) => {
    commit('SET_PERSONALITY_TEST_NOTIFICATION', payload)
  },

  setSuggestedNotifications: ({ commit }, payload) => {
    commit('SET_SUGGESTED_NOTIFICATIONS', payload)
  },

  setAmountRedeemable: ({ commit }, payload) => {
    commit('SET_AMOUNT_REDEEMABLE', payload)
  },
  setTransactions: ({ commit }, payload) => {
    commit('SET_TRANSACTIONS', payload)
  }
}
